import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import { getStore } from '../util/helper';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // console.log(to, from, next);
  // console.log('to.name:', to.name);
  let username = getStore('username');
  if (username != null && username.toLowerCase() == 'pkadmin') {
    
  }
  else if (to.matched.some(record => record.meta.requireAuth)) {
    let route_names = JSON.parse(getStore('route_names'));
    if (route_names && route_names.includes(to.name)) {
      next();
    } else {
      if (from.name == 'login') {
        alert("Permission Denied");
        return;
      }
      next({
        name: "login"
      });
    }
  }
  next();
});

export default router;
